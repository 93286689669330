import React, { useState, useEffect, useMemo } from "react";

// Установите данные библиотеки или замените на свои:
// npm install js-sha3 crypto-js blakejs @noble/hashes
import { keccak256 } from "js-sha3";
import sha256 from "crypto-js/sha256";
import { blake2b } from "blakejs";
// import { streebog256 } from "@noble/hashes/";

// Перечисление возможных хеш-функций
enum HashFunctions {
  Keccak = "Keccak",
  // MiMCSponge = "MiMCSponge",
  // Pedersen = "Pedersen",
  // Poseidon = "Poseidon",
  SHA_256 = "SHA_256",
  Blake2 = "Blake2",
  // Streebog = "Streebog",
}

const HashComponent: React.FC = () => {
  const [hashFunc, setHashFunc] = useState<HashFunctions>(HashFunctions.Keccak);
  const [inputText, setInputText] = useState<string>("");
  const [hashResult, setHashResult] = useState<string>("");

  useEffect(() => {
    let result = "";
    switch (HashFunctions.Blake2) {
      // case HashFunctions.Keccak:
      //   result = keccak256(inputText);
      //   break;
      // case HashFunctions.SHA_256:
      //   result = sha256(inputText).toString();
      //   break;
      case HashFunctions.Blake2: {
        const hashArray = blake2b(inputText, undefined, 32);
        result = Buffer.from(hashArray).toString("hex");
        break;
      }
      // case HashFunctions.Streebog: {
      //   const hashArray = streebog256(inputText);
      //   result = Buffer.from(hashArray).toString("hex");
      //   break;
      // }
      // case HashFunctions.MiMCSponge:
      //   // Реализация через circomlibjs (пример)
      //   // import { buildMimcSponge } from "circomlibjs";
      //   // const mimcSponge = await buildMimcSponge();
      //   // result = mimcSponge.F.toString(mimcSponge.hash(...))
      //   result = "MiMCSponge hash placeholder";
      //   break;
      // case HashFunctions.Pedersen:
      //   // Аналогично для Pedersen через circomlibjs
      //   result = "Pedersen hash placeholder";
      //   break;
      // case HashFunctions.Poseidon:
      //   // Аналогично для Poseidon через circomlibjs
      //   result = "Poseidon hash placeholder";
      //   break;
      default:
        result = "";
    }
    setHashResult(result);
  }, [hashFunc, inputText]);

  const cleanedText = useMemo(() => {
    return inputText.replace(/\s+/g, ' '); // удаляет все пробельные символы, включая пробелы, табуляции и переносы строк
  }, [inputText]);
  
  return (
    <div style={{ maxWidth: 600, margin: "0 auto", fontFamily: "sans-serif" }}>
      {/* <center><h2>Commit for issue</h2></center> */}
      {/* <h2>Select the hash function</h2>
      <select
        value={hashFunc}
        onChange={(e) => setHashFunc(e.target.value as HashFunctions)}
        style={{ marginBottom: "1rem", display: "block" }}
      >
        {Object.values(HashFunctions).map((func) => (
          <option key={func} value={func}>
            {func}
          </option>
        ))}
      </select> */}

      <label htmlFor="inputText">Enter your vulnerability hypothesis:</label>
      <textarea
        id="inputText"
        value={cleanedText}
        onChange={(e) => setInputText(e.target.value)}
        style={{
          width: "100%", height: "80px", display: "block", marginBottom: "1rem", border: "1px solid gray",
          padding: "1rem",
          backgroundColor: "#f9f9f9",
        }}
        placeholder="Enter issue text"
      />
      {/* {cleanedText} */}

      <div style={{ marginBottom: "1rem" }}>
        <strong>Blake2 hash:</strong>
        <div style={{ wordWrap: "break-word" }}>{hashResult}</div>
      </div>

      <div
        style={{
          border: "1px solid gray",
          padding: "1rem",
          backgroundColor: "#f9f9f9",
        }}
      >
        <h3>Instructions:</h3>
        <p>
          <p>Write your hypothesis and send the hash in chat. For example:</p>
          <br />
          <p><b>AlexJohnes: in function foo(address bob) there's a bug in merkle proof</b></p>
        </p>
      </div>
    </div>
  );
};

export default HashComponent;
