import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Commit from "../Commit/Commit";

const Main = lazy(() => import("../Main/Main"));
const Blog = lazy(() => import("../Blog/Blog"));
const BlogArticle = lazy(() => import("../Blog/BlogArticle"));
const CTF = lazy(() => import("../CTF/CTF"));
const ChallengePage = lazy(() => import("../CTF/ChallengePage"));
const Leaderboard = lazy(() => import("../CTF/Leaderboard/Leaderboard"));
const UserPage = lazy(() => import("../UserPage/UserPage"));
const ToolsPage = lazy(() => import("../Tools/ToolsPage"));
const Contact = lazy(() => import("../Contact/Contact"));
const NotFound = lazy(() => import("../WorkingProgress/WorkingProgress"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/commit" element={<Commit />} />
      <Route path="/blog/:id" element={<BlogArticle />} />
      <Route path="/ctf" element={<CTF />} />
      <Route path="/ctf/challenge/:id" element={<ChallengePage />} />
      <Route path="/leaderboard" element={<Leaderboard />} />
      <Route path="/user" element={<UserPage />} />
      <Route path="/tools" element={<ToolsPage />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default React.memo(AppRoutes);
