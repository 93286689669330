import React, { Suspense, useState } from "react";
import { Box, Grid, Flex } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import { AddressContext } from "../../contexts/addressContext";
import { UserContext } from "../../contexts/userContext";
import AppRoutes from "./AppRoutes"; // Вынесенные маршруты

const App = () => {
  const [address, setAddress] = useState(null);
  const [user, setUser] = useState(null);

  return (
    <AddressContext.Provider value={{ address, setAddress }}>
      <UserContext.Provider value={{ user, setUser }}>
        <BrowserRouter>
          <Flex direction="column" minH="100vh">
            <NavBar />
            <Box flex="1">
              <Suspense fallback={<div>Loading...</div>}>
                <AppRoutes />
              </Suspense>
            </Box>
            <Footer />
          </Flex>
        </BrowserRouter>
      </UserContext.Provider>
    </AddressContext.Provider>
  );
};

export default React.memo(App);
